import axios from 'axios';
import { errorResponse } from '../helpers/Helpers';
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = '';

if (process.env.NODE_ENV === 'production') {
	Api = `${REACT_APP_PRODURL}credits`;
} else {
	Api = `${REACT_APP_URL}credits`;
}

export const getCreditsEntregadosUncancelled = async (token, sucursalId) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetCreditsEntregadoUncancelled/' + sucursalId;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const desembolsarCreditAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/DesembolsarCredit/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getCreditGarantiasAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/CreditGarantias/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getCreditQuotesAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetCreditQuotes/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getQuoteSugestAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetQuoteSugest/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getSaldoAnteriorAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetSaldoAnterior/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getCreditPaymentAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetPaymentCredit/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const deleteCreditAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/DeleteCredit/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getCreditByIdAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetCreditById/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getCreditDeniedByIdAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetCreditDeniedById/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getCreditsNoEntregados = async (token, sucursalId) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetCreditsNoEntregado/' + sucursalId;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getCreditsEntregadosCancelled = async (token, sucursalId) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetCreditsCancelled/' + sucursalId;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getCreditsDenied = async (token, sucursalId) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetCreditsDenied/' + sucursalId;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addCreditAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddCredit/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addCreditNominalAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddCreditNominal/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addQuoteAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddQuote/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addCreditNoteAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddNotaCredito/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const liquidateCreditAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/LiquidateCredit/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addQuoteNominalAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddQuoteNominal/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addGarantiaAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddGarantia/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const updateCreditAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/UdateCredit/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const updateGarantiaAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/UdateGarantia/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const deleteQuoteAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/DeleteQuote/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const deleteQuoteNominalAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/DeleteQuoteNominal/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const remakeCreditAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/RemakeCredit/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const deleteGarantiaAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/DeleteGarantia/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const updateFechaPagoAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/UpdateFechaPago/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addCreditNoteNominalAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddCreditNoteNominal/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const liquidateCreditNominalAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/LiquidateCreditNominal/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const denegarCreditAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/DenegarCredit/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addGastoAministrativoAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddGastoAdministrativo/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getBillDetailsAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetQuoteById/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getAtrasoDiasAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetAtrasoDias/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getDataCreditNominalAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetDataCreditNominal/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getDataCreditAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetDataCredit/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addQuoteInteresAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddQuoteInteres/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const cancelarCapitalAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/CancelarCapital/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getCreditsAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/GetCredit/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const soldGarantiaAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/SellGarantia/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addAbonoCapitalAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AbonoCapital/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addQuoteConSobranteAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddQuoteConSobrante/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};
